<template>
  <swiper-container
    init="false"
    ref="swiperRef"
    :slides-per-view="slidesPerView"
    :autoplay="autoplay && { delay: autoplayDelay }"
    :pagination="pagination"
    :navigation="navigation"
    :space-between="spaceBetween"
    :breakpoints="breakpoints"
    rewind="true"
    class="h-full"
  >
    <slot></slot>
  </swiper-container>
</template>

<script setup lang="ts">
import { register } from 'swiper/element/bundle';

const parishConfig = useParishConfig()

register()

const swiperRef = ref(null) as Ref<any>
const props = defineProps({
  autoplay: { type: Boolean, default: false },
  autoplayDelay: { type: Number, default: 5000 },
  slidesPerView: { type: Number, default: 1 },
  navigation: { type: Boolean, default: false },
  pagination: { type: Boolean, default: false },
  spaceBetween: { type: Number, default: 0 },
  breakpoints: { type: Object, default: {} },
})

onMounted(() => {
  const params = {
    pagination: props.pagination && {
      clickable: true,
    },
    injectStyles: [
      `
      .swiper-pagination-bullet {
        border: 1px solid rgba(255, 255, 255, 0.3);
        background-color: transparent;
        margin: 0 0.25rem 1.5rem !important;
        width: 0.875rem;
        height: 0.875rem;
      }

      .swiper-pagination-bullet-active {
        background-color: rgba(255, 255, 255, 0.6);
      }

      .swiper-button-next,
      .swiper-button-prev {
        display: none;
        width: auto;
        height: auto;
        margin-top: 0 !important;
        top: 50%;
        transform: translateY(-50%);
        padding: 2rem;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.7);
      }

      .swiper-button-next svg,
      .swiper-button-prev svg {
        display: none;
      }

      .swiper-button-prev {
        left: 4rem;
      }

      .swiper-button-next {
        right: 4rem;
      }

      .swiper-button-next::after,
      .swiper-button-prev::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .swiper-button-next::after {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='${encodeURIComponent(parishConfig.value.colors.primary)}'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
        background-size: 1.5rem;
        background-repeat: no-repeat;
        background-position: center;
      }

      .swiper-button-prev::after {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='${encodeURIComponent(parishConfig.value.colors.primary)}'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
        background-size: 1.5rem;
        background-repeat: no-repeat;
        background-position: center;
      }

      @media (min-width: 1024px) {
        .swiper-button-next,
        .swiper-button-prev {
          display: block;
        }
      }
    `,
    ],
  }

  Object.assign(swiperRef.value, params)
  swiperRef.value.initialize()
})
</script>
